import React from "react";
import "./index.css";

import logoCongressoBranco from "../../../Assets/logoCongressoBranco.png";
import logoSystemic from "../../../Assets/logoSystemic.png";
import logoTeutonia from "../../../Assets/logoTeutonia.png";
import logoRedeSinodal from "../../../Assets/logoRedeSinodal.png";
import logoColegioTeutonia from "../../../Assets/logoColegioTeutonia.png";
import { listaContatosCT, listaRedesSociaisCT } from "../../../Config/listas";

import { scrollToTop } from "../../../Config/functions";


export default function Footer(){

	return(
		<footer id="contato">
			<div className="container-logos">
				<button onClick={() => scrollToTop()}>
					<img src={logoCongressoBranco}  alt="Logo do Congresso Internacional de Educação" />
				</button>
				<div className="container-realizacao">
					<span>Realização:</span>
					<div className="logos-realizacao">
						<a href="http://www.colegioteutonia.com.br/" target="_blank" rel="noreferrer">						
							<img src={logoColegioTeutonia} alt="Logo do Colégio Teutônia"/>
						</a>
						<a href="https://redesinodal.com.br/" target="_blank" rel="noreferrer">
							<img src={logoRedeSinodal} alt="Logo da Rede Sinodal de Educação"/>
						</a>
					</div>
					<span>Apoio:</span>
					<div className="logos-apoio">
						<a href="https://systemic.com.br/" target="_blank" rel="noreferrer">
							<img src={logoSystemic} alt="Logo do Systemic Bilingual" className="logo-systemic"/>
						</a>
						<a href="https://teutonia.rs.gov.br/" target="_blank" rel="noreferrer">
							<img src={logoTeutonia} alt="Logo da Prefeitura de Teutônia" className="logo-prefeitura-teutonia"/>
						</a>
					</div>
				</div>
			</div>
			<div className="container-contato">
				<span>Entre em contato conosco:</span>
				<div className="container-links">
					{
						listaContatosCT.map((item, index) => (
							<span key={index}>
								{item.icone}
								<a href={item.href}>{item.endereco}</a>
							</span>
						))
					}
					<div className="container-redes-sociais">
						{
							listaRedesSociaisCT.map ((item, index) => (
								<a href={item.endereco} key={index} target="_blank" rel="noreferrer">{item.icone}</a>
							))
						}
					</div>
				</div>
			</div>
			<div className="container-direitos">
				<span>3º Congresso Internacional de Educação. Todos os direitos reservados. ©2024</span>
				<span>Desenvolvido por Colégio Teutônia.</span>
			</div>
		</footer>
	);
}
