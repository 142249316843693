import React from "react";
import "./index.css";
import NorthEastIcon from "@mui/icons-material/NorthEast";

import { listaRestaurantes } from "../../Config/listas";

export default function Restaurantes(){
	return(
		<section className="container-restaurantes">
			<div className="container-grid">
				{listaRestaurantes.map((restaurante, index) => (
					<div key={index} className="box-restaurante">
						<img src={`/Assets/Restaurantes/${restaurante.img}`} alt={`Foto do restaurante ${restaurante.nome}`} />
						<div className="texto-restaurante">
							<span className="restaurante-nome">{restaurante.nome}</span>
							<span className="restaurante-descricao">Distância do Congresso: <span className="restaurante-distancia">{restaurante.distancia}</span></span>
							<div className="restaurante-botao">
								<a href={restaurante.localizacao} target="blank">
									ABRIR MAPA <NorthEastIcon fontSize="inherit"/>
								</a>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}