import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Inicio from "./pages/Inicio";
import PaginaPadrao from "./pages/PaginaPadrao";
import Hoteis from "./pages/Hoteis";
import Participantes from "./pages/Participantes";
import Restaurantes from "./pages/Restaurantes";

export default function AppRouter(){
	return(
		<BrowserRouter>
			<Routes>
				<Route path='/' element={ <PaginaPadrao/> }>
					<Route index element={ <Inicio/> }/>
					<Route path='/hoteis' element={ <Hoteis/> } />
					<Route path='restaurantes' element={ <Restaurantes/> } />
					<Route path='/:id' element={ <Participantes/> } />
					<Route path='*' element={ <Navigate to="/" /> }/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}