import React from "react";
import "./index.css";

import imagemParticipantes from "../../../Assets/imagemParticipantes.png";
import { listaParticipantes } from "../../../Config/listas";
import { scrollToTop } from "../../../Config/functions";

import { useNavigate } from "react-router-dom";
import NorthEastIcon from "@mui/icons-material/NorthEast";

export default function Participantes(){

	const navigate = useNavigate();

	const navigateToParticipante = (url :string) => {
		navigate(`/${url}`);

		scrollToTop();
	};

	return(
		<section className="container-participantes">
			<div className="container-grid">
				{listaParticipantes.map((participante, index) => (
					<div key={index} className="box-participante">
						<img src={`/Assets/SumarioParticipantes/${participante.imgSumario}`} alt={`Foto do participante ${participante.nome}`} />
						<div className="texto-participante">
							<span className="participante-nome">{participante.nome} <br/> {participante.sobrenome}</span>
							<span className="participante-descricao">{participante.descricaoSumario}</span>
							<div className="participante-botao">
								<button onClick={() => navigateToParticipante(participante.url)}>
									SAIBA MAIS <NorthEastIcon fontSize="inherit"/>
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="box-imagem">
				<img src={imagemParticipantes} alt="Imagem sobre participantes" />
			</div>
		</section>
	);
}