import React, { useEffect, useState } from "react";
import "./index.css";

import { listaParticipantes } from "../../Config/listas";

import { useNavigate, useParams } from "react-router-dom";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { scrollToTop } from "../../Config/functions";


export default function Participantes(){

	const params = useParams();
	const navigate = useNavigate();

	const [participanteAtual, setParticipanteAtual] = useState <typeof listaParticipantes[0]> ();
	const [outrosParticipantes, setOutrosParticipantes] = useState <typeof listaParticipantes> ();

	useEffect(() => {
		const participanteEncontrado = listaParticipantes.find(participante => participante.url === params.id);
		setOutrosParticipantes(listaParticipantes.filter(participante => participante.url !== params.id));

		if (!participanteEncontrado) {
			navigate("/");
		} else {
			setParticipanteAtual(participanteEncontrado);
		}
	}, [params.id]);


	const navigateToParticipante = (url :string) => {
		navigate(`/${url}`);

		scrollToTop();
	};


	return(
		<section className="container-pagina-participantes">
			<div className="container-dados-participante">
				<div className="container-nome">
					<div className="container-conheca">
						<span>CONHEÇA</span>
						<span>{participanteAtual?.nome}</span>
						<span>{participanteAtual?.sobrenome}</span>
					</div>
					<img src={`/Assets/Participantes/${participanteAtual?.imgPrincipal}`} alt={`Foto do participante ${participanteAtual?.nome}`} />
				</div>
				<div className="container-informacoes">
					<span>{participanteAtual?.descricaoPrincipal}</span>
				</div>
				<div className="container-redes">
					<span>Nas redes sociais:</span>
					<ul>
						{participanteAtual?.redesSociais.map((item, index) => (
							<li key={index}>
								<a href={item.endereco}  target="_blank" rel="noreferrer">
									<span>{item.icone}</span>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="container-dados-outros">
				<span className="titulo-outros">CONHEÇA OS PARTICIPANTES</span>
				<div className="container-tela-grid">
					{outrosParticipantes?.map((participante, index) => (
						<div key={index} className="box-tela-participante">
							<img src={`/Assets/SumarioParticipantes/${participante.imgSumario}`} alt={`Foto do participante ${participante.nome}`} />
							<div className="texto-tela-participante">
								<span className="participante-nome">{participante.nome} <br/> {participante.sobrenome}</span>
								<span className="participante-descricao">{participante.descricaoSumario}</span>
								<div className="participante-tela-botao">
									<button onClick={() => navigateToParticipante(participante.url)}>
									SAIBA MAIS <NorthEastIcon fontSize="inherit"/>
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}