import React, { useState } from "react";

import Header from "../../Components/PaginaPadrao/Header";
import HeaderResponsivo from "../../Components/PaginaPadrao/HeaderResponsivo";
import Footer from "../../Components/PaginaPadrao/Footer";

import { Outlet } from "react-router-dom";


export default function PaginaPadrao(){
	const [abrirHeaderResponsivo, setAbrirHeaderResponsivo] = useState(false);
    
	return(
		<main id="congresso">
			<Header abrirHeaderResponsivo={abrirHeaderResponsivo} setAbrirHeaderResponsivo={setAbrirHeaderResponsivo}/>
			<HeaderResponsivo abrirHeaderResponsivo={abrirHeaderResponsivo} setAbrirHeaderResponsivo={setAbrirHeaderResponsivo}/>
			<Outlet/>
			<Footer/>
		</main>
	);
}