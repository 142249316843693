import React from "react";
import "./index.css";

import { linkSympla } from "../../../Config/links";
import { listaLinksHeaderResponsivo } from "../../../Config/listas";
import logoCongresso from "../../../Assets/logoCongresso.png";
import { scrollToTop, scrollToSectionMobile } from "../../../Config/functions";

import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface HeaderResponsivoProps {
  abrirHeaderResponsivo: boolean;
  setAbrirHeaderResponsivo: React.Dispatch<React.SetStateAction<boolean>>;
}


export default function HeaderResponsivo({ abrirHeaderResponsivo, setAbrirHeaderResponsivo }: HeaderResponsivoProps) {
	
	const location = useLocation();
	const navigate = useNavigate();

	const scroll = (section: string) => {

		if (location.pathname !== "/"){

			/* Navegar para a programação ou localização da página principal*/
			if (section === "programacao" || section === "localizacao"){
				navigate("/", 
					{ state: { key: `${section}` } }
				);

				setAbrirHeaderResponsivo(false);

				return;
			}

			/* Navegar para o topo da página principal */
			if (section === "congresso"){
				navigate("/");

				setAbrirHeaderResponsivo(false);

				scrollToTop();

				return;
			}
		}

		if (location.pathname === "/hoteis"){

			setAbrirHeaderResponsivo(false);

			/* Navegar o topo da página /hotel */
			if (section === "hoteis"){

				scrollToTop();
				
				return;
			}
		}

		if (location.pathname === "/restaurantes"){

			setAbrirHeaderResponsivo(false);

			/* Navegar o topo da página /hotel */
			if (section === "restaurantes"){

				scrollToTop();
				
				return;
			}
		}

		/* Navegar da pagina principal para o topo da página /hotel */
		if (section === "hoteis"){
			navigate("/hoteis");

			setAbrirHeaderResponsivo(false);

			scrollToTop();

			return;
		}

		
		/* Navegar da pagina principal para o topo da página /restaurantes */
		if (section === "restaurantes"){
			navigate("/restaurantes");

			setAbrirHeaderResponsivo(false);

			scrollToTop();

			return;
		}

		/* Restante  */
		scrollToSectionMobile(section);

		setAbrirHeaderResponsivo(false);

	};

	return (
		<div className={`header-responsivo ${abrirHeaderResponsivo ? "header-ativado" : "header-desativado"}`}>
			<div className="container-header-responsivo">
				<div className="container-navegacao">
					<div className="botao-fechar-responsivo" onClick={() => setAbrirHeaderResponsivo(!abrirHeaderResponsivo)}>
						<CloseIcon fontSize="large" color="inherit" />
					</div>
					<ul className="itens-navegacao-responsivo"> 
						{listaLinksHeaderResponsivo.map((item, index) => (
							<li key={index}>
								<button onClick={() => scroll(item.link)}>
									{item.icon}{item.name}
								</button>
							</li>
						))}
					</ul>
					<div className="botao-inscricao-responsivo">
						<a href={linkSympla} target="_blank" rel="noreferrer">Avaliação</a>
					</div>
				</div>
				<div className="container-imagens">
					<span>@colegioteutonia.com.br</span>
					<img src={logoCongresso} alt="Logo do Congresso Internacional de Educação"/>
				</div>
			</div>
		</div>
	);
}
