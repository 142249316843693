import { animateScroll, scroller } from "react-scroll";

export const scrollToTop = () => {
	animateScroll.scrollToTop({
		duration: 700,
		smooth: true,
		offset: -94
	});
};

export const scrollToSection = (section : string)=> {
	scroller.scrollTo(section, {
		duration: 700,
		smooth: true,
		offset: -94,
	});
};	

export const scrollToSectionMobile = (section : string)=> {
	scroller.scrollTo(section, {
		duration: 700,
		smooth: true,
		offset: -60,
	});
};