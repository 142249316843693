import React from "react";
import "./index.css";

import imagemCT from "../../../Assets/imagemCT.png";
import { linkMaps } from "../../../Config/links";


export default function Localizacao(){

	const abrirGoogleMaps = () => {
		window.open(linkMaps, "_blank");
	};

	return(
		<section className="container-localizacao" id="localizacao">
			<div className="container-imagem-ct">
				<img src={imagemCT} alt="“Do coração à razão: por uma Educação Inovadora”"/>
			</div>
			<div className="container-acesso">
				<div className="texto-acesso">
					<h4>COMO CHEGAR NO CONGRESSO?</h4>
					<a href={linkMaps} target="_blank" rel="noreferrer">RUA ASIDO DREYER, 1285 - TEUTÔNIA RS</a>
				</div>
				<div className="botao-acesso">				
					<button onClick={abrirGoogleMaps}>Clique para definir a rota</button>
				</div>
			</div>
		</section>
	);
}