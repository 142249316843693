import React, { useState } from "react";
import "./index.css";

import AtividadesDia01 from "./Atividades/AtividadeDia01";
import AtividadesDia02 from "./Atividades/AtividadeDia02";
import { listaBotoesProgramacao } from "../../../Config/listas";


export default function Programacao(){

	const [alterarDia, setAlterarDia] = useState(0);

	return(
		<section className="container-programacao" id="programacao">
			<h2>PROGRAMAÇÃO</h2>
			<div className="container-tabela">
				<div className="fundo-botao">
					{listaBotoesProgramacao.map((item, index) => (
						<button key={index} className={`botao-base ${alterarDia === index ? "botao-ativado" : "botao-desativado"}`} onClick={() => setAlterarDia(index)}>
							<span>{item.dia}</span>
							<span>{item.data}</span>
						</button>
					))}
				</div>
				{alterarDia === 0 ? <AtividadesDia01/> : <AtividadesDia02/>}
			</div>
		</section>
	);
}