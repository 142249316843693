import React, { useState } from "react";
import "./index.css";

import { listaDuvidas } from "../../../Config/listas";

import SouthEastIcon from "@mui/icons-material/SouthEast";


export default function Duvidas() {
	const [abrirPergunta, setAbrirPergunta] = useState(-1);

	return (
		<section className="container-duvidas">
			<h4>DÚVIDAS FREQUENTES</h4>
			<div className="container-perguntas">
				{listaDuvidas.map((item, index) => (
					<div key={index} className="container-pergunta">
						<div className="pergunta" onClick={() => setAbrirPergunta(abrirPergunta === index ? -1 : index)}>
							<span className="texto-pergunta">
								{item.pergunta} 
							</span>
							<span className={`icone-desativado ${abrirPergunta === index ? "icone-ativado" : ""}`}>
								<SouthEastIcon />
							</span>
						</div>
						<span className={`resposta ${abrirPergunta === index ? "resposta-ativado" : ""}`}>{item.resposta}</span>
					</div>
				))}
			</div>
		</section>
	);
}
