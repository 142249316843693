import React from "react";
import "./index.css";

import { listaProgramacaoDia01 } from "../../../../Config/listas";
import { scrollToTop, scrollToSection } from "../../../../Config/functions";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";


export default function AtividadesDia01(){

	const navigate = useNavigate();

	const navigateToParticipante = (url :string | undefined) => {
		
		if (url){
			navigate(`/${url}`);

			scrollToTop();
		}
	};

	return(
		<ul>
			<div className="horarios">
				{listaProgramacaoDia01.map((item, index) => (
					<li key={index} className={index === 6 ? "horario-multi" : (index === 2 || index === 4 || index === 8 || index === 10  ? "horario-uni" : "horario")}>
						<span>{item.horario}</span>
						<div className="bolinha"></div>
					</li>
				))}
				<li className="botao-voltar-programacao">
					<KeyboardArrowUpIcon onClick={() => scrollToSection("programacao")} fontSize="inherit" className="icone-voltar"/>
				</li>
			</div>
			<div className="informacoes">
				{listaProgramacaoDia01.map((item, index) => (
					<li key={index} className={index === 6 ? "informacao-multi" : (index === 2 || index === 4 || index === 8 || index === 10 ? "informacao-uni" : "informacao")}>
						<span className="horario-responsivo">{item.horario}</span>
						<span className="programacao-titulo"> {item.titulo} </span>
						<div className="programacao-descricao">
							<span> {item.informacoes} </span>	
							{item.palestrante && 
								<div className="programacao-participante">
									{item.imgPalestrante &&
										<img src={`/Assets/Programacao/${item.imgPalestrante}`} alt={`Foto do participante ${item.palestrante}`}/>
									}
									<span onClick={() => navigateToParticipante(item.url)}> 
										{item.palestrante} 
									</span>
								</div>
							}
							{item.palestrante2 && 
								<div className="programacao-participante">
									<img src={`/Assets/Programacao/${item.imgPalestrante2}`} alt={`Foto do participante ${item.palestrante2}`}/>
									<span onClick={() => navigateToParticipante(item.url2)}> 
										{item.palestrante2} 
									</span>
								</div>
							}
						</div>
					</li>
				))}
				<li className="texto-voltar-programacao">
					<span onClick={() => scrollToSection("programacao")}>Clique para voltar</span>
				</li>
			</div>
		</ul>
	);
}