import React from "react";
import "./index.css";

import { listaProgramacaoDia02 } from "../../../../Config/listas";
import { scrollToTop, scrollToSection } from "../../../../Config/functions";


import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";



export default function AtividadesDia02(){

	const navigate = useNavigate();

	const navigateToParticipante = (url :string | undefined) => {
		navigate(`/${url}`);

		scrollToTop();
	};

	return(
		<ul>
			<div className="horarios">
				{listaProgramacaoDia02.map((item, index) => (
					<li key={index} className={index === 1 || index === 3 ? "horario-uni" : "horario"}>
						<span>{item.horario}</span>
						<div className="bolinha"></div>
					</li>
				))}
				<li className="botao-voltar-programacao">
					<KeyboardArrowUpIcon onClick={() => scrollToSection("programacao")} fontSize="inherit" className="icone-voltar"/>
				</li>
			</div>
			<div className="informacoes">
				{listaProgramacaoDia02.map((item, index) => (
					<li key={index} className={index === 1 || index === 3 ? "informacao-uni" : "informacao"}>
						<span className="horario-responsivo">{item.horario}</span>
						<span className="programacao-titulo"> {item.titulo} </span>
						<div className="programacao-descricao">
							<span> {item.informacoes} </span>	
							{item.palestrante && 
								<div className="programacao-participante">
									<img src={`/Assets/Programacao/${item.imgPalestrante}`} alt={`Foto do participante ${item.palestrante}`}/>
									<span onClick={() => navigateToParticipante(item.url)}> 
										{item.palestrante} 
									</span>
								</div>
							}
						</div>
					</li>
				))}
				<li className="texto-voltar-programacao">
					<span onClick={() => scrollToSection("programacao")}>Clique para voltar</span>
				</li>
			</div>
		</ul>
	);
}