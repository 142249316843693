import React, { useEffect } from "react";

import BannerPrincipal from "../../Components/Inicio/BannerPrincipal";
import Participantes from "../../Components/Inicio/Participantes";
import Programacao from "../../Components/Inicio/Programacao";
import Duvidas from "../../Components/Inicio/Duvidas";
import Localizacao from "../../Components/Inicio/Localizacao";
import Marquee from "../../Components/Inicio/Marquee";

import { useLocation } from "react-router-dom";

export default function Inicio() {

	const location = useLocation();

	/* Rolagem para a determinada section */
	useEffect(() => {
		if (location.state){
			const elemento = document.getElementById(location.state.key);

			if (elemento) {
				const elementoTop = elemento.getBoundingClientRect().top;
				window.scrollBy({ top: elementoTop - 62, left: 0, behavior: "smooth" });
			}
		}
	}, [location]);

	
	return (
		<>
			<BannerPrincipal />
			<Participantes />
			<Programacao/>
			<Marquee />
			<Duvidas />
			<Localizacao />
		</>
	);
}
