import React from "react";
import "./index.css";

import { listaHoteis } from "../../Config/listas";


export default function Hoteis(){

	return(
		<section className="container-hoteis" id="hoteis">
			<ul>
				{listaHoteis.map((hotel, index) => (
					<li key={index}>
						<img src={`/Assets/Hoteis/${hotel.img}`} alt={`Imagem do ${hotel.nome}`} />
						<div className="hotel-informacao">
							<div className="hotel-descricao">
								<span className="hotel-nome">{hotel.nome}</span>
								<span className="hotel-distancia">Distância do Congresso: {hotel.distancia}</span>
							</div>
							<div className="hotel-botao">
								<a href={hotel.site} target="_blank" rel="noreferrer">ACESSAR SITE</a>
							</div>
						</div>
					</li>
				))}
			</ul>
		</section>
	);
}
