import React from "react";
import "./index.css";

import logoCongresso from "../../../Assets/logoCongresso.png";


export default function BannerPrincipal(){
	return(
		<section className="container-banner-principal">
			<img src={logoCongresso} alt="Logo do Congresso Internacional de Educação" />
			<div className="container-texto">
				<h1>&quot;DO CORAÇÃO À RAZÃO: POR UMA EDUCAÇÃO INOVADORA&quot;</h1>
				<h4>8 e 9 DE FEVEREIRO/COLÉGIO TEUTÔNIA</h4>
			</div>
		</section>
	);
}