import React from "react";
import "./index.css";

import { linkSympla } from "../../../Config/links";
import { listaLinksHeader } from "../../../Config/listas";
import { scrollToTop, scrollToSection } from "../../../Config/functions";
 
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

interface HeaderProps {
  abrirHeaderResponsivo: boolean;
  setAbrirHeaderResponsivo: React.Dispatch<React.SetStateAction<boolean>>;
}


export default function Header({ abrirHeaderResponsivo, setAbrirHeaderResponsivo }: HeaderProps) {
	
	const location = useLocation();
	const navigate = useNavigate();
	
	const scroll = (section: string) => {

		if (location.pathname !== "/"){

			/* Navegar para a programação ou localização da página principal*/
			if (section === "programacao" || section === "localizacao"){
				navigate("/", 
					{ state: { key: `${section}` } }
				);

				return;
			}

			/* Navegar para o topo da página principal */
			if (section === "congresso"){
				navigate("/");
			
				scrollToTop();
	
				return;
			}
		}

		if (location.pathname === "/hoteis"){

			/* Navegar o topo da página /hotel */
			if (section === "hoteis"){

				scrollToTop();
				
				return;
			}
		}


		if (location.pathname === "/restaurantes"){

			/* Navegar o topo da página /restaurantes */
			if (section === "restaurantes"){

				scrollToTop();
				
				return;
			}
		}

		/* Navegar da pagina principal para o topo da página /hotel */
		if (section === "hoteis"){
			navigate("/hoteis");

			scrollToTop();

			return;
		}

		/* Navegar da pagina principal para o topo da página /restaurantes */
		if (section === "restaurantes"){
			navigate("/restaurantes");

			scrollToTop();

			return;
		}

		/* Restante  */

		scrollToSection(section);
	};
	
	
	return (
		<header>
			<div className="abrir-menu" onClick={() => setAbrirHeaderResponsivo(!abrirHeaderResponsivo)}>
				<MenuIcon fontSize="large" color="inherit"/>
			</div>
			<ul className="container-header">
				{listaLinksHeader.map((item, index) => (
					<li key={index} className="item-header">
						<button onClick={() => scroll(item.link)}>
							{item.name}
						</button>
					</li>
				))}
			</ul>
			<div className="botao-inscricao">
				<a href={linkSympla} target="_blank" rel="noreferrer"> Avaliação </a>
			</div>
		</header>
	);
}
