import React from "react";
import "./index.css";

export default function Marquee(){
	return(
		<section className="container-marquee">
			<div>
				<span className="frase-1">Transforme o mundo através da Educação.</span>
				<span className="frase-2">Desperte Ideias.</span>
				<span className="frase-3">Transforme o Mundo.</span> 
				<span className="frase-4">Unindo emoção e razão em Educação.</span>
			</div>
			<div>
				<span className="frase-1">Transforme o mundo através da Educação.</span>
				<span className="frase-2">Desperte Ideias.</span>
				<span className="frase-3">Transforme o Mundo.</span> 
				<span className="frase-4">Unindo emoção e razão em Educação.</span>
			</div>
		</section>
	);
}